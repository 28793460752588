.teaserView {
  border: 1px solid #323232;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  border-radius: 0.5rem;
}

.fullView {
  border: 1px solid #323232;
  margin-bottom: 1.25rem;
  border-radius: 0.5rem;
  padding: 1.25rem;
}

.content {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  word-break: break-word;
}

.content code {
  font-size: 1em !important;
  font-family: "DM Sans", "Helvetica Neue", sans-serif;
  background-color: #30281e;
  color: #cacaca;
}
.tagWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.tag {
  background-color: #212121 !important;
  border: 0;
  border-radius: 2rem !important;
  padding: 0.7rem 1rem !important;
  font-size: 0.75rem !important;
  margin-right: 6px;
  margin-bottom: 1rem;
  color: #898989 !important;
  border: 1px solid #212121;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  cursor: default;
}
.tagMedium {
  max-width: 85px !important;
}
.tagLarge {
  max-width: 138px !important;
}

.tagActive {
  border: 1px solid #90795d;
}
.icon {
  font-size: 32px;
  margin-top: 0.25rem;
  margin-left: 2.5rem;
}

.trimImage {
  max-height: 300px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.image {
  max-width: 100%;
  margin: 0.25rem 0 1.5rem;
}

.copyLink {
  cursor: pointer;
  background-color: #212121;
  border: 1px solid #464646;
  border-radius: 0.35rem;
  padding: 0.25rem;
  font-size: 0.75rem;
  text-align: center;
  width: 115px;
  color: #898989 !important;
  font-weight: 700;
  display: inline-block;
}
.copyLink img {
  margin-right: 5px;
}
.dimText {
  color: #898989;
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  .teaserView,
  .fullView {
    border: 0px;
    border-radius: 0;
    border-bottom: 1px solid #323232;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .copyLink {
    width: 98px;
  }
  .tagMedium {
    max-width: 72px !important;
  }
  .tagLarge {
    max-width: 125px !important;
  }
  .tagLarge:last-child {
    max-width: 95px !important;
  }
}
