.emptySearchResult {
  padding: 5rem 3rem;
  text-align: center;
  color: #898989;
}
.searchResultIcon {
  display: block;
  margin: 1rem auto;
}
.seeAllPins {
  display: block;
  padding: 12px 18px;
  background-color: #90795d;
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #212121 !important;
  text-decoration: none;
  width: 120px;
  margin: 3rem auto 0px;
}
.seeAllPins:hover,
.seeAllPins:active {
  color: #212121 !important;
}
.resultCountMsg {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}