.navbarWrapper {
  border-bottom: 1px solid #464646;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #111 !important;
}
.navbar {
  font-size: 1.25rem;
  background-color: #111 !important;
  height: 80px;
  display: flex;
  padding-top: 0.625rem;
}
.menuNav {
  visibility: visible;
  position: sticky;
  top: 6.25rem;
}
.logoNav {
  text-decoration: none;
  padding: 1rem;
  flex-grow: 30;
  max-width: 235px;
}
.navLink {
  text-decoration: none;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  color: #cacaca !important;
  display: block;
}
.navLink:hover {
  color: #cacaca !important;
}
/* .rightLink { 
  text-decoration: none;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  color: #cacaca !important;
  display: inline-block;
} */
.icon {
  font-size: 36px;
  margin-left: 2rem;
}
.logoText {
  width: 110px;
  padding-top: 7px;
}
.searchWrapper {
  margin-left: 7% !important;
  flex-grow: 35;
  position: relative;
  max-width: 330px;
}
.searchInput {
  width: 80%;
  background-color: transparent !important;
  border: 1px solid #464646 !important;
  padding: 1rem !important;
  padding-left: 3rem !important;
  border-radius: 2rem !important;
  background: transparent url(../../resources/searchIcon.svg) no-repeat 1rem
    center !important;
  color: #cacaca !important;
}
.searchInput + .btnClose {
  display: block;
}
.searchInput:placeholder-shown + .btnClose {
  display: none;
}
.searchInput:-webkit-autofill,
.searchInput:-webkit-autofill:hover,
.searchInput:-webkit-autofill:focus {
  -webkit-text-fill-color: #cacaca;
  -webkit-box-shadow: 0 0 0px 40rem #111 inset;
}

.siteLikeLink {
  margin-right: auto;
}

.siteLike {
  background-color: #212121;
  padding: 0.5rem !important;
  height: 60px;
  border-radius: 0.5rem;
  color: #cacaca;
}
.siteLikeIcon {
  margin-top: 0.5rem;
}
.siteLikeText {
  font-size: 0.75rem;
  line-height: 0.8rem;
  color: #898989;
}
.profileIcon {
  margin-top: 0.5rem;
}

.pinIconMenu {
  margin-top: -5px;
  padding-right: 0.5rem;
  height: 27px;
}
.authorIconMenu {
  margin-top: -5px;
  margin-left: -4px;
  padding-right: 0.5rem;
  height: 24px;
}

.btnClose {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-image: url("../../resources/inputClose.svg");
  background-color: transparent;
  border: 0;
  background-position: center center;
  padding: 0;
}

.siteLikeInPorgress {
  opacity: 0.3;
  cursor: default;
}

.privacyLink {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: black;
  padding: 0.75rem;
  font-size: 0.75rem;
  text-align: right;
  padding-right: 3rem;
  display: none;
}

.privacyLink a,
.privacyLink a:link,
.privacyLink a:visited {
  color: #898989;
  text-decoration: none;
}

@media (max-width: 767px) {
  .navbar {
    padding-top: 4rem !important;
    height: 116px;
  }
  .logoNav {
    padding-left: 1px;
  }
  .menuNav {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    margin-left: 0;
    left: 0;
    height: 80px;
    background-color: #212121;
    top: auto;
  }
  .navLink {
    flex-grow: 1;
    height: 80px;
    padding: 2rem;
    text-align: center;
  }
  .searchWrapper {
    display: none;
  }
  .siteLikeLink {
    /* margin-right: 2rem; */
  }
  .siteLike {
    padding-top: 8px !important;
    height: 55px;
  }
  .searchFixedOnMobile {
    display: block !important;
    width: 100%;
    position: fixed !important;
    background-color: #111 !important;
    margin-left: 0 !important;
    top: 0;
    height: 116px;
    padding: 3rem;
    flex-grow: 100;
    margin-left: -15px !important;
    max-width: initial;
    z-index: 1;
  }
  .searchFixedOnMobile input {
    width: 100% !important;
  }
  .searchFixedOnMobile .btnClose {
    top: 4rem;
    right: 4rem;
    /* position: absolute;
    width: 32px;
    top: 0;
    right: 0;
    background-image: url("data:image/svg+xml,%0A%3Csvg class='svg-icon' style='width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M777.856 280.192l-33.92-33.952-231.872 231.872-231.84-231.872-33.984 33.888 231.872 231.904-231.84 231.84 33.888 33.984 231.904-231.904 231.84 231.872 33.952-33.888-231.872-231.904z' fill='%23B3B3B3'/%3E%3C/svg%3E") !important;
    height: 32px;
    background-color: transparent;
    border: 0;
    display: block !important; */
  }
  .searchInput:placeholder-shown + .btnClose {
    display: block;
  }
  .privacyLink {
    padding: 1.5rem;
    bottom: 80px;
  }
}
