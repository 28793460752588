html {
  background-color: #111 !important;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "DM Sans", "Helvetica Neue", sans-serif;
  background-color: #111 !important;
  color: #cacaca;
  font-size: 1rem;
  overflow-y: scroll;
  padding-top: 5rem;
}
.container {
  max-width: 990px;
}
.modal-dialog {
  border: 3px solid #555;
}
.modal-dialog .btn-close {
  background-image: url("data:image/svg+xml,%0A%3Csvg class='svg-icon' style='width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M777.856 280.192l-33.92-33.952-231.872 231.872-231.84-231.872-33.984 33.888 231.872 231.904-231.84 231.84 33.888 33.984 231.904-231.904 231.84 231.872 33.952-33.888-231.872-231.904z' fill='%23B3B3B3'/%3E%3C/svg%3E") !important;  
  background-size: cover;
}
.modal-dialog .modal-title {
  font-size: 1rem;
}
.modal-dialog .modal-content {
  background-color: #111 !important;
  color: #fff;
}
.modal-750w {
  max-width: 750px;
}
.modal-650w {
  max-width: 650px;
}

.modal-dialog .mrkPinContentonModal {
  max-height: 500px;
  overflow-y: auto;
}

.carousel-control-next,
.carousel-control-prev {
  width: 4%;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #555;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:link,
a:visited,
a:active {
  color: #4d90f3;
}

a:hover {
  /* color: #2196f3 !important; */
  color: #4d90f3 !important;
}

::placeholder {
  color: #898989 !important;
  opacity: 1 !important; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #898989 !important;
}

@media only screen and (max-width: 991px) {
  body .navbar-dark {
    padding: 0 0 1rem 0 !important;
  }
}

.ScrollbarsCustom-Wrapper {
  border-bottom: 1px solid #323232;
  padding-bottom: 1rem;
}

.ScrollbarsCustom-Wrapper:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(17, 17, 17, 0),
    rgba(17, 17, 17, 1) 90%
  );
  width: 100%;
  height: 4em;
}

.ScrollbarsCustom-Thumb {
  background-color: #3A3A3A !important;
}
.menu-navigation a.navitem:hover,
.menu-navigation a.navitem.active {
  background-color: #212121;
}
.menu-navigation a.navitem.active {
  font-weight: 600;
}
a[href="#mpReadMore"],
a[href="#mpReadMore"]:link,
a[href="#mpReadMore"]:visited,
a[href="#mpReadMore"]:active,
a[href="#mpReadMore"]:hover,
.readMore,
.readMore:link,
.readMore:visited,
.readMore:active {
  color: #cacaca !important;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.offcanvas-backdrop {
  display: none !important;
}

.tooltip .tooltip-inner {
  background-color: #242424;
  color: #696969;
}
.tooltip .tooltip-arrow::before {
  border-bottom-color: #242424;
}
.popover {
  border-radius: 5px;
  border: 1px solid #979797;
}
.popover .popover-body {
  background-color: #979797;
  color: #000;
}
.popover .popover-arrow::after {
  border-bottom-color: #979797;
}

.scroll-to-top {
  background-color: transparent !important;
  box-shadow: none !important;
}

.privacy-link.on-top {
  display: block;
}

.page-privacy-policy .privacy-link {
  display: none;
}

@media (max-width: 767px) {
  html {
    background-color: #111 !important;
    font-size: 0.9rem;
  }
  body {
    padding-top: 8rem;
  }
  body.mobileFixedHeader .navbarWrap {    
    background-color: #111 !important;
  }
  .outerContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
  .offcanvas-backdrop {
    display: block !important;
  }
  .mrkPinTeaserView .row .col-first,
  .mrkPinFullView .row .col-first {
    padding-left: 0px;
  }
  .mrkPinTeaserView .row .col-last,
  .mrkPinFullView .row .col-last {
    padding-right: 0px;
  }
  .scroll-to-top {
    bottom: 110px !important;
  }
  .privacy-link.on-top {
    display: none;
  }
  .page-author .privacy-link {
    display: block;
  }
  .page-author .scroll-to-top {
    bottom: 160px !important;
  }
}
