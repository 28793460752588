.tagFilterWrapper {
  position: sticky;
  top: 6.25rem;
}
.filterByTagsTitle {
  font-size: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #323232;
  cursor: pointer;
}
.filterByTagsTitleOpen {
  border-bottom: 0;
}
.filterIcon {
  margin-top: -2px;
  margin-right: 1rem;
}
.arrowIcon {
  margin-top: -2px;
  margin-left: 1rem;
}
.filterByTagsTitleOpen .arrowIcon {
  background-color: #292d32;
  border-radius: 1rem;
}

.searchTagInput {
  background-color: transparent !important;
  border: 1px solid #464646 !important;
  padding: 1rem !important;
  padding-left: 3rem !important;
  border-radius: 2rem !important;
  background: transparent url(../../resources/searchIcon.svg) no-repeat 1rem center !important;
  color: #cacaca !important;
}

.apldFilters {
  margin-top: 1.75rem;
}
.apldFilters h2 {
  color: #898989;
  font-size: 0.75rem;
}
.apldFilters h2 a {
  font-size: 0.875rem;
  float: right;
  line-height: 1rem;
}
.apldFiltersListWrap {
  margin-top: 1rem;
  padding-bottom: 1.25rem;
}
.apldFiltersListWrap p {
  color: #cacaca;
  font-size: 0.875rem;
  opacity: 60%;
  margin-bottom: 1.5rem;
}
.apldFiltersListWrap ul {
  list-style-type: none;
  padding-left: 0;
}
.apldFiltersListWrap ul li {
  margin-bottom: 1.5rem;
}
.apldFiltersListWrap ul li input {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.65rem;
  background-color: transparent;
  border-color: #464646;
}
.apldFiltersListWrap ul li label {
  font-size: 0.875rem;
  line-height: 1.75rem;
}
.filtersApplied {
  color: #696969;
  font-size: 0.875rem;
  margin-top: 1rem;
  display: block !important;
}
.filtersApplied span,
.tagsCount {
  display: inline-flex;
  padding: 4px 12px;
  color: #90795d;
  border-radius: 20px;
  background: rgba(144, 121, 93, 0.2);
  margin-left: 4px;
}
.filterOffcanvas {
  background-color: #131313 !important;
  width: 310px !important;
  color: #cacaca !important;
  display: none !important;
}
.filterOffcanvasHd {
  padding-top: 3.5rem !important;
  padding-bottom: 0 !important;
  font-size: 0.875rem !important;
  font-size: 0.875rem !important;
}
.filterOffcanvasHd div {
  font-size: 0.875rem !important;
}
.filterOffcanvasHd button {
  background-image: url("data:image/svg+xml,%0A%3Csvg class='svg-icon' style='width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M777.856 280.192l-33.92-33.952-231.872 231.872-231.84-231.872-33.984 33.888 231.872 231.904-231.84 231.84 33.888 33.984 231.904-231.904 231.84 231.872 33.952-33.888-231.872-231.904z' fill='%23B3B3B3'/%3E%3C/svg%3E") !important;
  height: 1.5rem;
  width: 1.5rem;
  background-size: contain;
  cursor: pointer;
  top: 0.75rem;
  position: absolute;
  right: 0.75rem;
  display: none; /* requested to remove by user */
}
.showOnTabAbove {
  display: block;
}
.filterByTagsTitle .tagsCount {
  display: none;
}
@media (max-width: 950px) {
  .filterByTagsTitle {
    font-size: .8rem;
    padding: 1rem .25rem;
  }
}

@media (max-width: 767px) {
  .tagFilterWrapper {
    position: static;
    top: auto;
  }
  .filterOffcanvas {
    display: flex !important;
  }
  .showOnTabAbove {
    display: none;
  }
  .filterByTagsTitle {
    text-align: right;
    padding: 1rem 0;
    border-bottom: 0px;
    font-size: 1rem;
  }  
  .filterByTagsTitle .arrowIcon {
    display: none;
  }
  .filterByTagsTitle .tagsCount {
    display: inline-flex;
  }
  .filtersApplied {
    display: none !important;
  }
}

