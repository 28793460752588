.loadMoreWrapper {
  display: flex;
  justify-content: center;
  margin: 2rem 1rem;
}
.loadMore {
  color: #999;
  text-decoration: none;
  border: 1px solid;
  padding: 0.5rem 2rem;
}
.loading {
  color: #333;
  text-decoration: none;
  border: 1px solid;
  padding: 0.5rem 2rem;
}
