.banner {
  text-align: center;
  position: relative;
  height: 7.25rem;
}
.banner .bannerBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 135px;
}
.banner .profilePic {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100px;
}

.name {
  font-size: 1.125rem;
  color: #cacaca;
  text-align: center;
  padding: 4rem 0 0;
}
.title {
  font-size: 0.875rem;
  color: #898989;
  text-align: center;
}

.coverLetter {
  text-align: center;
  margin-top: 2rem;
  color: #898989;
}
.link {
  display: inline-flex;
  text-align: center;
  font-size: 0.75rem;
  color: #898989 !important;
  text-decoration: none;
  margin: 2rem 3rem;
  border-radius: 8px;
  font-weight: bold;
  padding: 0.75rem 1rem;
  background-color: #212121;
  position: relative;
  padding-right: 2.5rem;
}
.link img {
  position: absolute;
  right: 9px;
  top: 9px;
}
.linkedIn {
  text-align: center;
  padding-top: 0.5rem;
}

@media (max-width: 767px) {
  .banner {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -20px;
  }
}