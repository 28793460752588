.navRightItems {
  flex-grow: 35;
  text-align: right;
  min-width: 295px;
}
.navRightItemsLink {
  float: right;
  /* border: 1px solid !important; */
}

.searchIcon {
  display: none !important;
}
.siteLikeIcon {
  margin-top: 0.5rem;
}

.signIn {
  padding: 3px !important;
  height: 45px;
  color: #cacaca;
  margin-left: 0.5rem !important;
}
.signIn .signInText {
  display: block;
}

.loginName {
  margin-left: 1rem !important;
  display: inline-block !important;
  vertical-align: text-top;
  margin-top: 7px;
}
.loginName span {
  display: inline-block;
  color: #2c1736;
  background-color: #5f4d67;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  padding-top: 5px;
}
.signInMenu {
  margin-top: 5px;
}

@media only screen and (max-width: 991px) {
  .navRightItems {
    min-width: auto;
  }
}

@media (max-width: 767px) {
  .navRightItems {
    padding: 0;
  }
  .searchIcon {
    display: inline-block !important;
    padding: 3px 0 !important;
    margin-right: 1rem;
  }
  .signIn {
    margin-left: 0 !important;
    padding-right: 0 !important;
  }
  .signIn .signInText {
    display: none;
  }
  .loginName {
    margin-left: 1rem !important;
    margin-top: 3px;
  }
  .signInMenu {
    margin-top: 0;
  }
}
