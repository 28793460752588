.wrapper {
  position: relative;
  padding: 0;
  width: 105px;
  height: 60px;
  text-align: center;
  padding-left: 2rem;
  color: #898989;
  background-color: #212121;
  border-radius: 0.5rem;
  padding-top: 8px;
}
.icon {
  position: absolute;
  left: 18px;
  top: 15px;
}
.count {
  font-size: 16px;
  color: #cacaca;
}
.label {
  font-size: 12px;
}

@media (max-width: 767px) {
  .wrapper {
    width: 75px;
    height: 45px;
  }
  .icon {
    left: 8px;
    top: 8px;
  }
  .count {
    font-size: 14px;
  }
  .label {
    font-size: 10px;
  }
}
