.title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #898989;
}

.text {
  color: #898989;
}
.text h2 {
  font-size: 1.5rem;
  color: #cacaca;
}
.text h3 {
  font-size: 1.25rem;
  color: #cacaca;
}
.text h4 {
  font-size: 1.15rem;
  color: #cacaca;
}
